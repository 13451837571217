import { useState } from "react";
import { Input } from "./../ui";

const BudgetField = ({
  defaultValue,
  onChange,
}: {
  defaultValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [text, setText] = useState(defaultValue);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
    onChange(e)
  }

  return (
    <Input
      value={text}
      placeholder="Your budget"
      containerStyle={{
        width: "100%",
        justifySelf: "end",
        maxWidth: "300px",
      }}
      onChange={handleOnChange}
    />
  );
};

export default BudgetField;
