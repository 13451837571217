export enum ContactPreferencesInputType {
  textarea = "textarea",
  number = "number",
  date = "date",
  currency = "currency",
  pets = "pets",
}

export interface IContactPreferencesForm {
  id: string;
  name: string;
  slug: string;
  form_fields: IContactPreferencesFormField[];
}

export interface IContactPreferencesFormField {
  id: string;
  label: string;
  slug: string;
  input_type: ContactPreferencesInputType;
  placeholder_text: string;
  min_value: number | null;
  max_value: number | null;
  is_default: boolean;
  display_order: number;
}
