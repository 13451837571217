import { useLocation, useNavigate, useParams } from "react-router-dom";
import TimeScheduledDisplay from "./timeScheduledDisplay";
import { useMemo } from "react";
import { useContact, useEvent, useProperties } from "../api/queries";
import { Button } from "./ui";

const ConfirmationDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companySlug, propertySlug, eventId } = useParams();
  const isCancelingEvent = useMemo(() => {
    const lastRoute = location.pathname.split("/").filter(Boolean).pop();
    return lastRoute === "cancel";
  }, [location]);

  const { data: event } = useEvent(eventId || "");
  const { data: contact } = useContact(event?.guests[0] || "");
  const { data: properties } = useProperties({
    slug: propertySlug,
    company__slug: companySlug,
  });

  const title = useMemo(() => {
    if (eventId) {
      if (isCancelingEvent) {
        return "Cancellation confirmed";
      } else {
        return `We’ll see you soon, ${contact?.first_name}!`;
      }
    }
  }, [isCancelingEvent, contact]);

  const handleGoToFirstStep = () => {
    navigate("../..", { relative: "path" });
  };

  return (
    <div className="flex flex-col items-center mt-9 gap-4">
      <h2 className="font-medium text-[24px] text-center tracking-[0.3px]">
        {title}
      </h2>

      <TimeScheduledDisplay />

      <p className="text-center mt-4">
        {isCancelingEvent
          ? `Your tour at ${
              properties ? properties[0]?.name : ""
            } has been canceled.`
          : "You’ll get a calendar invite to your email, and a text reminder so you don’t miss your tour."}
      </p>

      {isCancelingEvent && (
        <Button label="Schedule a tour" onClick={handleGoToFirstStep} />
      )}
    </div>
  );
};

export default ConfirmationDetails;
