import validator from 'validator'

import { cleanPhoneNumber } from './phoneNumber'

export const isFilled = (value: unknown) => {
  if (typeof value === "undefined" || value === null) {
    return false;
  }

  if (typeof value === "number" || typeof value === "boolean") {
    return true;
  }

  if (typeof value === "object" && value !== null) {
    return true;
  }

  if (typeof value === "string") {
    return value.trim().length > 0;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
};

export const isEmail = (email: string) => {
  return validator.isEmail(email);
};

export const isPhoneNumber = (phoneNumber: string) => {
  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
  return validator.isMobilePhone(cleanedPhoneNumber, "en-US");
};

export default {
  isFilled,
  isEmail,
  isPhoneNumber,
};
