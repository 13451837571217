import { useScheduler } from "../contexts/SchedulerContext";
import dayjs from "dayjs";
import { AvailabilityTimeslot } from "../types/scheduler";
import { useMemo } from "react";

const HourBubble = ({
  availability,
  onSelect,
}: {
  availability: AvailabilityTimeslot;
  onSelect?: () => void;
}) => {
  const { selectedHour, setDate, setSelectedHour } = useScheduler();

  const timeValue = useMemo(() => {
    return dayjs(availability.start_time).format("h:mm A").toLowerCase();
  }, [availability.start_time]);

  const isSelected = useMemo(() => {
    return (
      dayjs(selectedHour?.start_time).format("h:mm A").toLowerCase() ===
      timeValue
    );
  }, [timeValue, selectedHour?.start_time]);

  const handleSetTime = () => {
    setSelectedHour(availability);
    setDate(new Date(availability.start_time));
    onSelect?.();
  };

  return (
    <div
      className={`py-3 border-1 border-border rounded-xl max-w-[80px] w-full flex justify-center cursor-pointer transition ease-in-out duration-300 ${
        isSelected && "border-primary-action bg-primary-action"
      } hover:border-primary-action hover:bg-primary-action hover:text-white`}
      onClick={handleSetTime}
    >
      <span
        className={`font-medium text-center ${
          isSelected && "text-[#fff]"
        } select-none`}
      >
        {timeValue}
      </span>
    </div>
  );
};

export default HourBubble;
