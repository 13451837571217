import { useRef, useEffect, useState } from 'react'

function useDocumentTitle() {
  const defaultTitle = useRef(document.title);
  const [title, setTitle] = useState(defaultTitle.current);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    document.title = defaultTitle.current;
  }, [])

  function resetTitle() {
    setTitle(defaultTitle.current);
  }

  return [title, setTitle, resetTitle] as const;
}

export default useDocumentTitle
