import axios from "axios";

const APP_ENV = import.meta.env.VITE_APP_ENV;
let API_URL, API_VERSION, API_KEY;

if (APP_ENV === "production") {
  API_URL = import.meta.env.VITE_API_URL_PROD;
  API_VERSION = import.meta.env.VITE_API_VERSION_PROD;
  API_KEY = import.meta.env.VITE_API_KEY_PROD;
} else if (APP_ENV === "staging" || APP_ENV === "preview") {
  API_URL = import.meta.env.VITE_API_URL_STAGING;
  API_VERSION = import.meta.env.VITE_API_VERSION_STAGING;
  API_KEY = import.meta.env.VITE_API_KEY_STAGING;
}

if (typeof API_URL === "undefined" || API_URL === null) {
  API_URL = import.meta.env.VITE_API_URL;
}

if (typeof API_VERSION === "undefined" || API_VERSION === null) {
  API_VERSION = import.meta.env.VITE_API_VERSION;
}

if (typeof API_KEY === "undefined" || API_KEY === null) {
  API_KEY = import.meta.env.VITE_API_KEY;
}

const versionedBaseURL = `${API_URL}/${API_VERSION}`;

const api = axios.create({
  baseURL: `${versionedBaseURL}/`,
  timeout: 10 * 1000, // 10 seconds
});

api.defaults.headers.common["Content-Type"] = "application/json";
api.defaults.headers.common["Authorization"] = `Api-Key ${API_KEY}`;

export default api;
export { API_URL, API_VERSION };
