import ConfirmationScreen from "./confirmationScreen";
import TourCancelScreen from "./tourCancelScreen";
import { useState } from "react";

type Step = "cancelation" | "confirmation";

const TourCanceling = () => {
  const [step, setStep] = useState<Step>("cancelation");

  const renderStep = () => {
    switch (step) {
      case "cancelation":
        return <TourCancelScreen toNextStep={() => setStep("confirmation")} />;
      case "confirmation":
        return <ConfirmationScreen />;
    }
  };

  return renderStep();
};

export default TourCanceling;
