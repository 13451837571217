import {
  createContext,
  useState,
  useContext,
  ReactNode,
} from "react";
import {
  AvailabilityTimeslot,
  IFormData,
  EventSubType,
} from "../types/scheduler";

interface SchedulerContextProps {
  details: IFormData;
  tourType: EventSubType;
  date: Date | null;
  selectedHour: AvailabilityTimeslot | null;
  dayAvailability: AvailabilityTimeslot[];
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setSelectedHour: React.Dispatch<
    React.SetStateAction<AvailabilityTimeslot | null>
  >;
  setDetails: React.Dispatch<React.SetStateAction<IFormData>>;
  setTourType: React.Dispatch<React.SetStateAction<EventSubType>>;
  setDayAvailability: React.Dispatch<
    React.SetStateAction<AvailabilityTimeslot[]>
  >;
}

const SchedulerContext = createContext<SchedulerContextProps | undefined>(
  undefined
);

export const SchedulerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [details, setDetails] = useState<IFormData>({
    full_name: "",
    email: "",
    phone_number: "",
  });
  const [tourType, setTourType] = useState<EventSubType>(
    EventSubType.agentScheduled
  );
  const [dayAvailability, setDayAvailability] = useState<
    AvailabilityTimeslot[]
  >([]);
  const [selectedHour, setSelectedHour] = useState<AvailabilityTimeslot | null>(
    null
  );

  return (
    <SchedulerContext.Provider
      value={{
        date,
        selectedHour,
        details,
        tourType,
        dayAvailability,
        setDate,
        setSelectedHour,
        setDetails,
        setTourType,
        setDayAvailability,
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useScheduler = (): SchedulerContextProps => {
  const context = useContext(SchedulerContext);
  if (!context) {
    throw new Error("useScheduler must be used within a DateTimeProvider");
  }
  return context;
};
