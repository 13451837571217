import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEvent } from "../api/queries";
import { useScheduler } from "../contexts/SchedulerContext";
import TimeScheduledDisplay from "./timeScheduledDisplay";
import { Button, Input } from "./ui";
import api from "../api";

const TourCancelScreen = ({ toNextStep }: { toNextStep: () => void }) => {
  const { eventId } = useParams();
  const { data: event } = useEvent(eventId || "");

  const { setDate } = useScheduler();
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (event) {
      setDate(new Date(event.start_datetime));
    }
  }, [event]);

  const handleCancelTour = async () => {
    await api.post(`calendar/events/${eventId}/cancel/`, {
      reason,
    });
    toNextStep()
  };

  return (
    <div className="flex flex-col gap-6 w-full max-w-[390px]">
      <h1 className="text-[32px] font-medium text-center tracking-[0.3px]">
        Cancel tour?
      </h1>
      <TimeScheduledDisplay />
      <Input
        label="Reason for canceling"
        value={reason}
        name="full_name"
        onChange={(e) => setReason(e.target.value)}
        placeholder=""
      />
      <Button label="Cancel tour" onClick={handleCancelTour} />
    </div>
  );
};

export default TourCancelScreen;
