/// <reference types="vite-plugin-svgr/client" />
import {
  ContactPreferencesInputType,
  IContactPreferencesFormField,
} from "../types/prefferences";
import { useCallback, useMemo } from "react";
import _debounce from "lodash/debounce";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import DatePickerField from "./preferences-fields/datePickerField";
import { IContact } from "../types/contact";
import { useParams } from "react-router-dom";
import { useContact, useEvent } from "../api/queries";
// https://stackoverflow.com/a/70314031
import BathroomIcon from "../assets/svgs/bathroom_icon.svg?react";
import BedroomIcon from "../assets/svgs/bedroom_icon.svg?react";
import BudgetIcon from "../assets/svgs/budget_icon.svg?react";
import CalendarIcon from "../assets/svgs/calendar_icon.svg?react";
import BudgetField from "./preferences-fields/budgetField";
import OtherPreferencesField from "./otherPreferencesField";

const PreferenceField = ({
  field,
}: {
  field: IContactPreferencesFormField;
}) => {
  const queryClient = useQueryClient();
  const { eventId } = useParams();

  const { data: event } = useEvent(eventId || "");

  const bedsOptions = [
    {
      label: "Studio+",
      value: 0,
    },
    {
      label: "1+",
      value: 1,
    },
    {
      label: "2+",
      value: 2,
    },
    {
      label: "3+",
      value: 3,
    },
    {
      label: "4+",
      value: 4,
    },
    {
      label: "5+",
      value: 5,
    },
  ];
  const bathsOptions = [
    {
      label: "1+",
      value: 1,
    },
    {
      label: "2+",
      value: 2,
    },
    {
      label: "3+",
      value: 3,
    },
    {
      label: "4+",
      value: 4,
    },
    {
      label: "5+",
      value: 5,
    },
  ];
  const { data: contact } = useContact(event ? event.guests[0] : "");

  const isOtherPreferences = useMemo(
    () => field.slug === "other-preferences",
    [field.slug]
  );

  const getNoPreferenceValue = () => {
    switch (field.input_type) {
      case ContactPreferencesInputType.number:
        return (field.min_value ?? 0) - 1;
      case ContactPreferencesInputType.date:
        return null;
      case ContactPreferencesInputType.pets:
        return null;
      case ContactPreferencesInputType.textarea:
        return null;
      case ContactPreferencesInputType.currency:
        return null;
      default:
        return "Not set";
    }
  };

  const preferenceValue = useMemo(() => {
    if (!contact) {
      return getNoPreferenceValue();
    }
    if (!(field.slug in contact.preferences)) {
      return getNoPreferenceValue();
    }

    return contact.preferences[field.slug];
  }, [contact]);

  const updateContact = useMutation({
    mutationFn: ({ slug, value }: { slug: string; value: string | number }) =>
      api.patch(`contacts/${contact?.id}/`, {
        preferences: {
          [slug]: value,
        },
      }),
    onMutate: async ({ slug, value }) => {
      await queryClient.cancelQueries({
        queryKey: ["contact", contact?.id],
      });
      const previousContact = queryClient.getQueryData([
        "contact",
        contact?.id,
      ]);
      queryClient.setQueryData(["contact", contact?.id], (old: IContact) => ({
        ...old,
        preferences: {
          ...old.preferences,
          [slug]: value,
        },
      }));
      return { previousContact };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        ["contact", contact?.id],
        context?.previousContact
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["contact", contact?.id],
      });
    },
  });

  const debounceUpdate = useCallback(
    _debounce(
      ({ slug, value }: { slug: string; value: string | number }) =>
        updateContact.mutate({ slug, value }),
      200
    ),
    []
  );

  const renderInput = () => {
    switch (field.slug) {
      case "bedrooms":
        return (
          <div className="flex gap-1 justify-self-end">
            {bedsOptions.map((option) => (
              <PreferenceOption
                key={option.label}
                option={option}
                onUpdate={() =>
                  updateContact.mutate({
                    slug: field.slug,
                    value: option.value,
                  })
                }
                isSelected={preferenceValue == option.value}
              />
            ))}
          </div>
        );
      case "bathrooms":
        return (
          <div className="flex gap-1 justify-self-end">
            {bathsOptions.map((option) => (
              <PreferenceOption
                key={option.label}
                option={option}
                onUpdate={() =>
                  debounceUpdate({ slug: field.slug, value: option.value })
                }
                isSelected={preferenceValue === option.value}
              />
            ))}
          </div>
        );
      case "budget":
        return (
          <BudgetField
            defaultValue={preferenceValue?.toString() || ""}
            onChange={(e) =>
              debounceUpdate({ slug: field.slug, value: e.target.value })
            }
          />
        );
      case "move-in":
        return (
          <DatePickerField
            value={preferenceValue as string}
            slug={field.slug}
            onUpdate={({
              slug,
              value,
            }: {
              slug: string;
              value: string | number;
            }) =>
              updateContact.mutate({
                slug: slug,
                value: value,
              })
            }
          />
        );
      case "other-preferences":
        return (
          <OtherPreferencesField
            defaultValue={preferenceValue?.toString() || ""}
            onChange={(e) =>
              debounceUpdate({ slug: field.slug, value: e.target.value })
            }
          />
        );
    }
  };

  return (
    <div
      className={`flex justify-between gap-[10px] relative ${
        isOtherPreferences ? "flex-col items-start" : "items-center"
      } ${field.slug === "move-in" && "z-10"}`}
    >
      <PreferenceFieldIcon
        slug={field.slug}
        label={
          isOtherPreferences
            ? "Anything specific you are looking for?"
            : field.label
        }
      />
      {renderInput()}
    </div>
  );
};

export default PreferenceField;

const PreferenceOption = ({
  option,
  isSelected = false,
  onUpdate,
}: {
  option: {
    label: string;
    value: number;
  };
  isSelected?: boolean;
  onUpdate: () => void;
}) => {
  return (
    <button
      className={`p-2 border-[1px] rounded-[4px] min-w-10 flex items-center justify-center cursor-pointer transition ease-in-out duration-300 hover:border-primary-action ${
        isSelected ? "border-primary-action" : "border-border"
      }`}
      onClick={onUpdate}
    >
      <span className="font-medium ">{option.label}</span>
    </button>
  );
};

const PreferenceFieldIcon = ({
  slug,
  label,
}: {
  slug: string;
  label: string;
}) => {
  const renderIcon = () => {
    switch (slug) {
      case "bedrooms":
        return <BedroomIcon />;
      case "bathrooms":
        return <BathroomIcon />;
      case "budget":
        return <BudgetIcon />;
      case "move-in":
        return <CalendarIcon />;
      default:
        return null;
    }
  };
  return (
    <div className="flex flex-col items-center">
      {renderIcon()}
      <PreferenceFieldLabel label={label} />
    </div>
  );
};

const PreferenceFieldLabel = ({ label }: { label: string }) => {
  return <span className="text-sm">{label}</span>;
};
