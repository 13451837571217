import ConfirmationDetails from "./confirmationDetails";
import { useContact, useEvent, usePreferencesForm } from "../api/queries";
import PreferenceField from "./preferencesField";
import { Button } from "./ui";
import { useParams } from "react-router-dom";

const PreferencesScreen = ({ toNextStep }: { toNextStep: () => void }) => {
  const { eventId } = useParams();
  const { data: event } = useEvent(eventId || "");
  const { data: contact } = useContact(event?.guests[0] || "");
  const { data, isLoading, isFetched } = usePreferencesForm({
    enabled: !!contact,
  });

  const availableFields = [
    "bedrooms",
    "bathrooms",
    "budget",
    "move-in",
    "other-preferences",
  ];

  return (
    <div>
      <ConfirmationDetails />

      <p className="font-medium mt-8">
        To better serve you during your visit, please tell us more about what
        you’re looking for.
      </p>

      <div className="flex flex-col gap-6 mt-12">
        {!isLoading &&
          isFetched &&
          data?.form_fields
            .filter((field) => availableFields.find((af) => af === field.slug))
            .map((field) => <PreferenceField key={field.id} field={field} />)}

        <Button label="Done" onClick={toNextStep} />
      </div>
    </div>
  );
};

export default PreferencesScreen;
