import ConfirmationScreen from "./confirmationScreen";
import TimePicker from "./timePicker";
import TourRescheduleScreen from "./tourRescheduleScreen";
import { useState } from "react";

type Step = "timePicker" | "reschedule" | "confirmation";

const TourRescheduler = () => {
  const [step, setStep] = useState<Step>("timePicker");

  const renderStep = () => {
    switch (step) {
      case "timePicker":
        return <TimePicker toNextStep={() => setStep("reschedule")} />;
      case "reschedule":
        return (
          <TourRescheduleScreen toNextStep={() => setStep("confirmation")} onBack={() => setStep('timePicker')}/>
        );
      case "confirmation":
        return <ConfirmationScreen />;
    }
  };

  return renderStep();
};

export default TourRescheduler;
