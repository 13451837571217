import TourSchedulerForm from "./tourSchedulerForm";
import TimePicker from "./timePicker";
import { useState } from "react";

type Step = "timePicker" | "form" | "preferences";

const TourScheduler = () => {
  const [step, setStep] = useState<Step>("timePicker");

  const renderStep = () => {
    switch (step) {
      case "timePicker":
        return <TimePicker toNextStep={() => setStep("form")} />;
      case "form":
        return <TourSchedulerForm onBack={() => setStep("timePicker")} />;
    }
  };

  return renderStep();
};

export default TourScheduler;
