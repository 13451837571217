import { useParams } from "react-router-dom";
import ConfirmationScreen from "./confirmationScreen";
import PreferencesScreen from "./preferencesScreen";
import { useEffect, useState } from "react";
import { useContact, useEvent } from "../api/queries";
import _ from "lodash";

type Step = "confirmation" | "preferences";

const TourPreferences = () => {
  const { eventId } = useParams();
  const { data: event } = useEvent(eventId || "");
  const { data: contact, isLoading } = useContact(event?.guests[0] || "");

  const [step, setStep] = useState<Step>("preferences");
  const [hasBeenChecked, setHasBeenChecked] = useState(false);

  useEffect(() => {
    if (contact && !hasBeenChecked) {
      setStep(_.isEmpty(contact?.preferences) ? "preferences" : "confirmation");
      setHasBeenChecked(true)
    }
  }, [contact, hasBeenChecked]);

  const renderStep = () => {
    switch (step) {
      case "preferences":
        return <PreferencesScreen toNextStep={() => setStep("confirmation")} />;
      case "confirmation":
        return <ConfirmationScreen />;
    }
  };

  return isLoading ? <div>Loading...</div> : renderStep();
};

export default TourPreferences;
