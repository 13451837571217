import { useOutletContext } from "react-router-dom";
import { CompanyContextType } from "./_layout";
import { useEffect } from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle";


export default function Company() {
  const { company } = useOutletContext<CompanyContextType>();
  const [_, setTitle, resetTitle] = useDocumentTitle();

  useEffect(() => {
    if (typeof company === "undefined" || company === null) {
      resetTitle();
      return;
    }

    setTitle(`${company?.name}`);
  })

  return (
    <div className="">
      <img src={company?.logo} alt={company?.name} width={40} height="auto" />
      <h1 className="font-bold">{company?.name}</h1>
      <p>{company?.id}</p>
    </div>
  );
}
