import { useParams } from "react-router-dom";
import api from "../api";
import TimeScheduledDisplay from "./timeScheduledDisplay";
import { Button } from "./ui";
import { useScheduler } from "../contexts/SchedulerContext";
import dayjs from "dayjs";
import { useEvent } from "../api/queries";

const TourRescheduleScreen = ({
  toNextStep,
  onBack,
}: {
  toNextStep: () => void;
  onBack: () => void;
}) => {
  const { date, tourType } = useScheduler();
  const { eventId } = useParams();
  const { data: event } = useEvent(eventId || "");

  const handleRescheduleTour = async () => {
    if (!eventId || !date) {
      return;
    }

    const endTime = dayjs(date).add(30, "minute").toISOString();
    await api.patch(`calendar/events/${eventId}/`, {
      created_by: event?.created_by,
      start_datetime: date.toISOString(),
      end_datetime: endTime,
      event_subtype: tourType,
    });
    toNextStep();
  };

  return (
    <div className="flex flex-col items-center w-full max-w-[390px] mt-9">
      <TimeScheduledDisplay hasGoBackButton onBack={onBack} />
      <h2 className="font-medium text-[24px] text-center tracking-[0.3px] mt-6">
        Reschedule your tour
      </h2>
      <p className="mt-3">
        We’ll send you an updated calendar invite with tour details.
      </p>
      <Button
        label="Reschedule tour"
        onClick={handleRescheduleTour}
        style={{ marginTop: "44px" }}
      />
    </div>
  );
};

export default TourRescheduleScreen;
