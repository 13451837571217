import { ICompany } from "../types/company";
import api from "./index";

export async function list(params: { slug?: string }) {
  const response = await api.get<ICompany[]>("/companies", { params });
  return response.data
}

export async function get(id: string) {
  return api.get<ICompany>(`/companies/${id}`);
}

export default {
  list,
  get,
}
