import { useState } from "react";

const OtherPreferencesField = ({
  defaultValue,
  onChange,
}: {
  defaultValue: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  const [text, setText] = useState(defaultValue);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    onChange(e);
  };

  return (
    <textarea
      value={text}
      className="w-full rounded-3xl border-border border-[1px] outline-none:focus p-4 text-sm placeholder:text-[#ACAFB8] focus:border-border focus:ring-0"
      placeholder="List any must haves, floor preference, size, etc."
      style={{ height: "96px" }}
      onChange={handleOnChange}
    />
  );
};

export default OtherPreferencesField;
