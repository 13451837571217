import { NylasScheduling } from "@nylas/react";
import DatePicker from "./datePicker";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import {
  useTeamByCompanyProperty,
  useTeamConfiguration,
  useCompanies,
  useProperties,
  useFutureAvailability,
} from "../api/queries";
import { useScheduler } from "../contexts/SchedulerContext";
import { AvailabilityTimeslot } from "../types/scheduler";
import { useEffect } from "react";

const NylasScheduler = () => {
  const { companySlug, propertySlug, eventId } = useParams();
  const { data: company } = useCompanies({ slug: companySlug });
  const { data: properties } = useProperties({
    slug: propertySlug,
    company__slug: companySlug,
  });

  const { data: teams } = useTeamByCompanyProperty(
    company?.id || "",
    properties ? properties[0].id : ""
  );

  const {
    data: configuration,
    isLoading,
    isFetched,
    isError,
  } = useTeamConfiguration(teams ? teams[0]?.id || "" : "");
  const { data: futureAvailability } = useFutureAvailability(
    configuration?.id || "",
    configuration?.scheduler.available_days_in_future || 0
  );

  const {
    dayAvailability,
    date,
    setDate,
    setDayAvailability,
    setSelectedHour,
  } = useScheduler();

  const filterEventsByDay = (
    events: AvailabilityTimeslot[] | undefined,
    targetDate: string
  ) => {
    if (!events || events.length === 0) {
      return [];
    }
    return events.filter((event) => {
      const eventStartDay = dayjs(event.start_time).format("YYYY-MM-DD");
      const eventEndDay = dayjs(event.end_time).format("YYYY-MM-DD");
      const targetDay = dayjs(targetDate).format("YYYY-MM-DD");
      return eventStartDay === targetDay || eventEndDay === targetDay;
    });
  };

  useEffect(() => {
    if (!futureAvailability) {
      return;
    }

    const firstAvailableDay = dayjs(
      futureAvailability?.data.time_slots[0].start_time * 1000
    );

    if (date && !eventId) {
      return;
    }

    if (!eventId) {
      setDate(firstAvailableDay.toDate());
    }

    if (dayAvailability.length > 0) {
      return;
    }

    setDayAvailability(
      futureAvailability.data.time_slots
        .filter((slot) =>
          dayjs(slot.start_time * 1000).isSame(eventId ? date: firstAvailableDay, "day")
        )
        .map((slot) => ({
          ...slot,
          start_time: new Date(slot.start_time * 1000),
          end_time: new Date(slot.end_time * 1000),
        }))
    );
  }, [futureAvailability, date]);

  return (
    <div className="flex flex-col gap-6 items-center my-4 w-full">
      {!isError && (
        <div className="flex flex-col items-center">
          <h3 className="font-medium text-[20px] text-center tracking-[0.3px]">
            What day & time are best for you?
          </h3>

          <div className="flex gap-3">
            <ClockIcon color="#94A3B8" height={24} width={24} />
            <span className="font-medium text-secondary-text">
              20 minute tour
            </span>
          </div>
        </div>
      )}
      {!isLoading && isFetched && date ? (
        <NylasScheduling
          configurationId={configuration?.id}
          mode="composable"
          className="w-full shadow-none"
          eventOverrides={{
            dateSelected: async (event, connector) => {
              setDate(dayjs(event.detail).toDate());
              const dayAvailability = filterEventsByDay(
                connector?.schedulerStore.get("availability"),
                event.detail
              );
              setDayAvailability(dayAvailability);
              setSelectedHour(null);
            },
          }}
          defaultSchedulerState={{
            selectedDate: date,
          }}
          nylasBranding={false}
        >
          <DatePicker />
        </NylasScheduling>
      ) : isError ? (
        <h3 className="font-medium text-[20px] text-center tracking-[0.3px]">
          We're working to get apartment tour scheduling ready for you, please check back later.
        </h3>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )};



export default NylasScheduler;
