import { useEffect, useMemo } from "react";
import {
  Navigate,
  Outlet,
  useOutletContext,
  useParams,
} from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { CompanyContextType } from "../_layout";
import { useProperties } from "../../../api/queries";

export default function PropertyLayout() {
  const { company } = useOutletContext<CompanyContextType>();
  const { propertySlug } = useParams();
  const [_, setTitle, resetTitle] = useDocumentTitle();
  const {
    data: properties,
    isLoading,
    isFetched,
    error,
  } = useProperties({ slug: propertySlug, company__slug: company?.slug });

  const property = useMemo(() => {
    return properties?.find((property) => property.slug === propertySlug);
  }, [properties]);

  const logoData = useMemo(() => {
    if (property?.logo) {
      return {
        logo: property.logo,
        name: property.name,
      };
    } else if (company?.logo) {
      return {
        logo: company.logo,
        name: company.name,
      };
    } else {
      return null;
    }
  }, [company, property]);

  useEffect(() => {
    if (!company || !propertySlug) {
      return;
    }

    if (!properties && isFetched) {
      throw new Error("Property not found");
    }
  }, [properties, isFetched, company, propertySlug]);

  useEffect(() => {
    if (
      typeof company === "undefined" ||
      company === null ||
      typeof properties === "undefined"
    ) {
      resetTitle();
      return;
    }

    setTitle(`${properties[0]?.name} Tour Scheduling`);
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to={`/${company?.slug}`} />;
  }

  return (
    company &&
    property && (
      <div className="flex flex-col gap-6 items-center w-full">
        {logoData && (
          <img className="h-16" src={logoData.logo} alt={logoData.name} />
        )}
        <Outlet />
      </div>
    )
  );
}
