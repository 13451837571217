export function cleanPhoneNumber(phoneNumber: string) {
  return ("" + phoneNumber).replace(/\D/g, "").trim();
}

export function formatPhoneNumber(phoneNumber: string) {
  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);

  const usNumber = cleanedPhoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (usNumber) {
    return `(${usNumber[2]}) ${usNumber[3]}-${usNumber[4]}`;
  }

  return cleanedPhoneNumber;
}

export function machineFormatPhoneNumber(origPhoneNumber: string) {
  const cleanedPhoneNumber = cleanPhoneNumber(origPhoneNumber);
  return cleanedPhoneNumber.length === 10
    ? `+1${cleanedPhoneNumber}`
    : `+${cleanedPhoneNumber}`;
}
