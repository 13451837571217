import { CSSProperties } from "react";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
}

export const Button = ({
  label,
  style,
  labelStyle,
  ...props
}: IButtonProps) => {
  return (
    <button
      className="p-[10px] bg-primary-action text-white rounded-3xl w-full flex justify-center items-center"
      style={style}
      {...props}
    >
      {!!label && (
        <span className="text-xs font-medium" style={labelStyle}>
          {label}
        </span>
      )}
    </button>
  );
};
