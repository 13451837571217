import { Navigate, Outlet, useParams } from "react-router-dom";
import { ICompany } from "../../types/company";
import { useEffect } from "react";
import { SchedulerProvider } from "../../contexts/SchedulerContext";
import { useCompanies } from "../../api/queries";

export type CompanyContextType = { company: ICompany | undefined };

export default function CompanyLayout() {
  const { companySlug } = useParams();
  const {
    data: company,
    isLoading,
    error,
    isFetched,
  } = useCompanies({ slug: companySlug });

  useEffect(() => {
    if (!company && isFetched) {
      throw new Error("Property not found");
    }
  }, [company, isFetched]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to="/" />;
  }

  return (
    <SchedulerProvider>
      <main className="container max-w-[600px] w-full p-6 bg-white flex items-start justify-center sm:items-center">
        <Outlet
          context={
            {
              company: company,
            } satisfies CompanyContextType
          }
        />
      </main>
    </SchedulerProvider>
  );
}
