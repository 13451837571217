import { IProperty } from "../types/property";
import api from "./index";

export async function list(params: { slug?: string, company__slug?: string }) {
  const response = await api.get<IProperty[]>("/properties", { params });
  return response.data
}

export async function get(id: string) {
  return api.get<IProperty>(`/properties/${id}`);
}

export default {
  list,
  get,
}
