import ConfirmationDetails from "./confirmationDetails";
import { useLocation, useParams } from "react-router-dom";
import { useContact, useEvent } from "../api/queries";
import { useMemo } from "react";

const ConfirmationScreen = () => {
  const { eventId } = useParams();

  const { data: event } = useEvent(eventId || "");
  const { data: contact } = useContact(event ? event.guests[0] : "");

  const location = useLocation();
  const isCancelingEvent = useMemo(() => {
    const lastRoute = location.pathname.split("/").filter(Boolean).pop();
    return lastRoute === "cancel";
  }, [location]);

  return (
    <div className="flex flex-col items-center gap-8">
      <ConfirmationDetails />
      {contact && !isCancelingEvent && (
        <p className="font-medium">
          Thanks, {contact.first_name}. We’ll see you soon.
        </p>
      )}
    </div>
  );
};

export default ConfirmationScreen;
