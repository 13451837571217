import { Outlet, useParams } from "react-router-dom";
import { useEvent } from "../../../../api/queries";
import { useEffect } from "react";
import { useScheduler } from "../../../../contexts/SchedulerContext";
import { EventSubType } from "../../../../types/scheduler";

export default function EventLayout() {
  const { eventId } = useParams();
  const { data: event, isLoading } = useEvent(eventId || "");
  const { setTourType, setDate } = useScheduler();

  useEffect(() => {
    if (event) {
      setTourType(event.event_subtype || EventSubType.agentScheduled);
      setDate(new Date(event.start_datetime));
    }
  }, [event]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
}
