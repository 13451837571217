import { CSSProperties } from "react";

interface IFormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  containerStyle?: CSSProperties
}

export const Input = ({ label, containerStyle, errorMessage, ...props }: IFormInputProps) => {
  const hasError =
    typeof errorMessage !== "undefined" && errorMessage.length > 0;

  return (
    <div className="flex flex-col gap-1" style={containerStyle}>
      {!!label && <label className="font-medium text-sm">{label}</label>}
      <input
        className="w-full rounded-3xl border-border border-[1px] outline-none:focus p-4 text-sm placeholder:text-[#ACAFB8] focus:border-border focus:ring-0"
        type="text"
        {...props}
      />
      {hasError && (
        <span className="text-xs text-[#FF6060]">{errorMessage}</span>
      )}
    </div>
  );
};
