import { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useScheduler } from "../contexts/SchedulerContext";
import { NylasDatePicker } from "@nylas/react";
import "./calendar/calendar.styles.css";

dayjs.extend(advancedFormat);

const DatePicker = () => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const { date, selectedHour } = useScheduler();
  const shadowHostRef = useRef<HTMLNylasDatePickerElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const selectedDate = useMemo(() => {
    if (dayjs(date).isSame(dayjs(), "day") && !selectedHour) {
      return "Today";
    }
    return dayjs(date).format(`dddd MMM Do ${selectedHour ? "h:mma" : ""}`);
  }, [date, selectedHour]);

  useEffect(() => {
    if (!isPickerVisible) {
      return;
    }

    const observer = new MutationObserver(() => {
      const shadowRoot = shadowHostRef.current?.shadowRoot;
      if (!shadowRoot) {
        return;
      }

      const pagination = shadowRoot.querySelector(".pagination") as HTMLElement;
      pagination.style.minWidth = "auto";

      const dayParts = shadowRoot.querySelectorAll('[part="ndp__day"]');
      dayParts.forEach((dayPart) => {
        const dayName = dayPart.textContent?.trim();
        if (dayName) {
          dayPart.textContent = dayName.charAt(0);
        }
      });
    });

    if (shadowHostRef.current) {
      const shadowRoot = shadowHostRef.current.shadowRoot;

      if (shadowRoot) {
        observer.observe(shadowRoot, { childList: true, subtree: true });
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [isPickerVisible, shadowHostRef]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      shadowHostRef.current &&
      !shadowHostRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsPickerVisible(false);
    }
  };

  return (
    <div className="flex justify-center relative w-full">
      <div
        className="cursor-pointer py-[10px] px-4 flex justify-between items-center  border-1 border-border rounded-full gap-3 w-full sm:w-64"
        onClick={() => setIsPickerVisible(!isPickerVisible)}
        ref={buttonRef}
      >
        <span className="text-sm font-medium select-none">{selectedDate}</span>
        <ChevronDownIcon
          height={18}
          width={18}
          className={`transform ${
            isPickerVisible ? "rotate-180" : "rotate-0"
          } duration-300 ease-in-out transition-transform`}
        />
      </div>

      <div
        className="absolute z-1 top-full max-h-72 overflow-hidden border-[1px] rounded-2xl p-4 max-w-80 w-full"
        style={{
          backgroundColor: "white",
          display: !isPickerVisible ? "none" : "block",
        }}
      >
        <NylasDatePicker
          ref={shadowHostRef}
          onDateSelected={() => setIsPickerVisible(false)}
        />
      </div>
    </div>
  );
};

export default DatePicker;
